import { Outlet } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import ContainerRight from 'components/container/right';
import LayoutProvider from 'pages/common-layout/layout-provider-context';
import adminMenuItems from 'assets/menus/side-menu/admin';

const AdminPage = (): JSX.Element => {
  const { setSideNavMenuItems } = useContext(LayoutProvider);

  const [routeName, setRouteName] = useState<string>('');

  const getMenus = async (): Promise<void> => {
    setSideNavMenuItems(adminMenuItems.list);
  };

  useEffect(() => {
    getMenus();
  }, []);

  return (
    <>
      <ContainerRight>
        <Outlet context={{ routeName, setRouteName }} />
      </ContainerRight>
    </>
  );
};

export default AdminPage;
