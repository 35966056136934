type FieldOptionPropertiesType = {
  name: string;
  title: string;
  checked: boolean;
};

type FieldOptionType = {
  closing_date_change: FieldOptionPropertiesType;
  preliminary_docs: FieldOptionPropertiesType;
  entity_assignment: FieldOptionPropertiesType;
  other: FieldOptionPropertiesType;
};

export const fieldOptions = (): FieldOptionType => ({
  closing_date_change: {
    name: 'closing_date_change',
    title: 'Closing Date Change',
    checked: false
  },
  preliminary_docs: {
    name: 'preliminary_docs',
    title: 'Preliminary Closing Docs',
    checked: false
  },
  entity_assignment: {
    name: 'entity_assignment',
    title: 'Entity Assignment',
    checked: false
  },
  other: {
    name: 'other',
    title: 'Other',
    checked: false
  }
});
