import { opportunityStatusEnv } from 'assets/constants/opportunity-status-env';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import { isChecked } from 'components/form/unit-switch';
import { isEmpty } from 'helpers/misc-helper';
import moment from 'moment';

export const other_transaction_note = {
  validate: function (oppurtunity: any, status: string, oldOppurtunity: any) {
    let errors: string[] = [];

    if (status == 'action') {
      const stateList = [oppurtunityStatusList.closing_sale_pending_amended];

      if (
        stateList.includes(oppurtunity?.opportunity_status_c) &&
        isEmpty(oppurtunity?.other_transaction_note)
      ) {
        errors.push(missingField(`Other Transaction Note`));
      }
    }

    return errors;
  }
};
