import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { EnterSalePendingAmendedEntity } from 'types/brokerage-action-types';
import { OpportunityEntity } from 'types/opportunity-types';

export const getInitialState = (
  opportunity: OpportunityEntity
): EnterSalePendingAmendedEntity => {
  return {
    opportunity_status_c: oppurtunityStatusList.closing_sale_pending_amended,
    forecasted_close_date: opportunity?.forecasted_close_date,
    close_date_c: opportunity?.close_date_c,
    contract_amendment_date_c: opportunity?.contract_amendment_date_c,
    addendum_no: opportunity?.addendum_no,
    addendum_notes: opportunity?.addendum_notes,
    transaction_notes: opportunity?.transaction_notes
  };
};

export const prepareRequestBody = (
  data: EnterSalePendingAmendedEntity
): EnterSalePendingAmendedEntity => {
  return {
    opportunity_status_c: oppurtunityStatusList.closing_sale_pending_amended,
    forecasted_close_date: data.forecasted_close_date,
    close_date_c: data.close_date_c,
    contract_amendment_date_c: data.contract_amendment_date_c,
    addendum_no: data.addendum_no,
    addendum_notes: data.addendum_notes,
    transaction_notes: data.transaction_notes,
    reasons: data.reasons,
    notes: data.notes
  };
};

export const getValidationFields = (): string[] => {
  return ['forecasted_close_date', 'close_date_c'];
};
