import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { EnterSalePendingAmendedEntity } from 'types/brokerage-action-types';

const initialEnterSalePendingAmended: EnterSalePendingAmendedEntity = {
  opportunity_status_c: oppurtunityStatusList.closing_diligence_period,
  forecasted_close_date: '',
  close_date_c: '',
  contract_amendment_date_c: '',
  addendum_no: '',
  addendum_notes: '',
  transaction_notes: ''
};

export default initialEnterSalePendingAmended;
