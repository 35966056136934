import { ObjectType } from 'types';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { offer_expiration_date_c } from './offer-expiration-date-c';
import { isEmpty } from 'helpers/misc-helper';
import DateUtility from 'helpers/date-helper';
import { missingField } from 'assets/validation-template';
import fieldLabel from 'assets/constants/fieldLabel';
import moment from 'moment-timezone';
import federalHolidayHelper from 'helpers/federal-holiday-helper';

export const close_date_c = {
  validate: function (
    oppurtunity: ObjectType,
    status: string,
    oldOppurtunity: ObjectType
  ) {
    let errors: any = [];

    if (status == 'edit') {
      const close_date = oppurtunity?.close_date_c?.trim();
      const forecasted_close_date = oppurtunity?.forecasted_close_date?.trim();
      const due_diligence_end_c = DateUtility.getDateString(
        oppurtunity?.due_diligence_end_c
      );
      const forecasted_dd_end_date =
        oppurtunity?.forecasted_dd_end_date?.trim();

      let stateList = [
        oppurtunityStatusList.closing_diligence_period,
        oppurtunityStatusList.closing_diligence_amended,
        oppurtunityStatusList.closing_sale_pending,
        oppurtunityStatusList.closing_sale_pending_amended,
        oppurtunityStatusList.closing_clear_to_close,
        oppurtunityStatusList.closing_cancel_contrac,
        oppurtunityStatusList.closed_purchased,
        oppurtunityStatusList.closing_termination_pending
      ];

      if (stateList.includes(oppurtunity?.opportunity_status_c)) {
        if (isEmpty(forecasted_dd_end_date) && isEmpty(forecasted_close_date)) {
          let closeDate = moment(close_date).unix();
          let ddEndDate = moment(due_diligence_end_c).unix();

          if (closeDate < ddEndDate) {
            errors.push('Close Date must be greater then or equal DD End Date');
            return errors;
          }
        }

        if (
          !isEmpty(forecasted_dd_end_date) &&
          isEmpty(forecasted_close_date)
        ) {
          let closeDate = moment(close_date).unix();
          let forecastedDDEndDate = moment(forecasted_dd_end_date).unix();

          if (closeDate < forecastedDDEndDate) {
            errors.push(
              'Close Date must be greater then or equal Forecasted DD End Date'
            );
            return errors;
          }
        }
      }
    }

    if (status === 'action') {
      const stateList = [
        oppurtunityStatusList.offer_accepted,
        oppurtunityStatusList.closing_diligence_period,
        oppurtunityStatusList.closing_diligence_amended,
        oppurtunityStatusList.closing_sale_pending,
        oppurtunityStatusList.closing_sale_pending_amended
      ];

      if (
        stateList.includes(oppurtunity?.opportunity_status_c) &&
        isEmpty(oppurtunity?.close_date_c)
      ) {
        errors.push(missingField(fieldLabel.closeDate));
        return errors;
      }

      if (
        (oppurtunity?.opportunity_status_c ==
          oppurtunityStatusList.closing_diligence_amended ||
          oppurtunity?.opportunity_status_c ==
            oppurtunityStatusList.closing_sale_pending_amended) &&
        !isEmpty(oppurtunity.forecasted_close_date) &&
        DateUtility.getDateString(oppurtunity.close_date_c) !==
          DateUtility.getDateString(oppurtunity.forecasted_close_date)
      ) {
        errors.push('Close Date must be equal to Forecasted Close Date');
        return errors;
      }

      if (stateList.includes(oppurtunity.opportunity_status_c)) {
        if (
          isEmpty(oppurtunity.forecasted_dd_end_date) &&
          isEmpty(oppurtunity.forecasted_close_date)
        ) {
          let closeDate = moment(oppurtunity?.close_date_c).unix();
          let ddEndDate = moment(
            DateUtility.getDateString(oppurtunity?.due_diligence_end_c)
          ).unix();
          if (closeDate < ddEndDate) {
            errors.push('Close Date must be greater then or equal DD End Date');
          }
        } else if (
          !isEmpty(oppurtunity.forecasted_dd_end_date) &&
          isEmpty(oppurtunity.forecasted_close_date)
        ) {
          let closeDate = moment(oppurtunity?.close_date_c).unix();
          let forecastedDDEndDate = moment(
            oppurtunity?.forecasted_dd_end_date
          ).unix();
          if (closeDate < forecastedDDEndDate) {
            errors.push(
              'Close Date must be greater then or equal Forecasted DD End Date'
            );
          }
        }
      }
    }

    return errors;
  },
  handleChange: (opportunity: ObjectType, originalOpportutniy: ObjectType) => {
    let result = {};

    let closeDate = DateUtility.getFormattedDateString(
      opportunity.close_date_c
    );

    const { validDate, message } =
      federalHolidayHelper.rolloverDateIfFallsOnFederalHolidayAndGetMessage(
        closeDate,
        fieldLabel.closeDate,
        true
      );

    result = {
      close_date_c_federal_holiday_validation_message: message
    };

    opportunity = {
      ...opportunity,
      close_date_c: validDate
    };

    result = {
      ...result,
      offer_expiration_date_c: offer_expiration_date_c.calculate(
        opportunity,
        originalOpportutniy
      ),
      close_date_c: validDate
    };

    return result;
  },
  calculate: (opportunity: any) => {
    let closeDate = opportunity.close_date_c;

    if (!isEmpty(closeDate)) {
      closeDate = DateUtility.rolloverWeekendDateTimeToWeekdayDateTime(
        moment(closeDate)
      ).toString();
      closeDate = DateUtility.getFormattedDateString(closeDate);
    }

    return closeDate;
  }
};
