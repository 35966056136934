import fieldLabel from 'assets/constants/fieldLabel';
import oppurtunityStatusList from 'assets/constants/oppurtunity-status-list';
import { missingField } from 'assets/validation-template';
import DateUtility from 'helpers/date-helper';
import { isEmpty } from 'helpers/misc-helper';
import moment from 'moment-timezone';

export const forecasted_close_date = {
  validate: function (oppurtunity: any, status: string, oldOppurtunity: any) {
    let errors: string[] = [];

    if (status == 'edit') {
      const close_date = oppurtunity?.close_date_c;
      const forecasted_close_date = oppurtunity?.forecasted_close_date;

      const due_diligence_end_c = oppurtunity?.due_diligence_end_c;
      const forecasted_dd_end_date = oppurtunity?.forecasted_dd_end_date;

      let stateList = [
        oppurtunityStatusList.closing_sale_pending,
        oppurtunityStatusList.closing_clear_to_close,
        oppurtunityStatusList.closed_purchased
      ];

      if (
        oldOppurtunity?.opportunity_status_c ==
          oppurtunityStatusList.closing_sale_pending_amended &&
        stateList.includes(oppurtunity?.opportunity_status_c)
      ) {
        if (
          !isEmpty(forecasted_close_date) &&
          close_date !== forecasted_close_date
        ) {
          errors.push('Close Date and Forecasted Close Date must be equal');
          return errors;
        }
      }

      stateList = [
        oppurtunityStatusList.closing_diligence_period,
        oppurtunityStatusList.closing_diligence_amended,
        oppurtunityStatusList.closing_sale_pending,
        oppurtunityStatusList.closing_sale_pending_amended,
        oppurtunityStatusList.closing_clear_to_close,
        oppurtunityStatusList.closing_cancel_contract,
        oppurtunityStatusList.closed_purchased,
        oppurtunityStatusList.closing_termination_pending
      ];

      if (stateList.includes(oppurtunity?.opportunity_status_c)) {
        if (
          !isEmpty(forecasted_dd_end_date) &&
          !isEmpty(forecasted_close_date)
        ) {
          let forecastedCloseDate = moment(forecasted_close_date).unix();
          let forecastedDDEndDate = moment(forecasted_dd_end_date).unix();

          if (forecastedCloseDate < forecastedDDEndDate) {
            errors.push(
              'Forecasted Close Date must be greater then or equal Forecasted DD End Date'
            );
            return errors;
          }
        }

        if (
          isEmpty(forecasted_dd_end_date) &&
          !isEmpty(forecasted_close_date)
        ) {
          let forecastedCloseDate = moment(forecasted_close_date).unix();
          let ddEndDate = moment(
            DateUtility.getDateString(due_diligence_end_c)
          ).unix();

          if (forecastedCloseDate < ddEndDate) {
            errors.push(
              'Forecasted Close Date must be greater then or equal to DD End Date'
            );
          }
        }
      }
    }

    if (status === 'action') {
      const forecasted_close_date = oppurtunity?.forecasted_close_date;

      const due_diligence_end_c = oppurtunity?.due_diligence_end_c;
      const forecasted_dd_end_date = oppurtunity?.forecasted_dd_end_date;

      let stateList = [
        oppurtunityStatusList.closing_diligence_period,
        oppurtunityStatusList.closing_diligence_amended,
        oppurtunityStatusList.closing_sale_pending,
        oppurtunityStatusList.closing_sale_pending_amended,
        oppurtunityStatusList.closing_clear_to_close
      ];

      const forecastedCloseDateInput = document.getElementById(
        'forecasted_close_date'
      );

      if (
        forecastedCloseDateInput?.getAttribute('disabled') !== '' &&
        stateList.includes(oppurtunity?.opportunity_status_c) &&
        isEmpty(forecasted_close_date)
      ) {
        errors.push(missingField(fieldLabel.forecastedCloseDate));
      }

      stateList = [
        oppurtunityStatusList.closing_diligence_period,
        oppurtunityStatusList.closing_diligence_amended,
        oppurtunityStatusList.closing_sale_pending,
        oppurtunityStatusList.closing_sale_pending_amended
      ];

      if (stateList.includes(oppurtunity.opportunity_status_c)) {
        if (
          !isEmpty(forecasted_dd_end_date) &&
          !isEmpty(forecasted_close_date)
        ) {
          let forecastedCloseDate = moment(forecasted_close_date).unix();
          let forecastedDDEndDate = moment(forecasted_dd_end_date).unix();

          if (forecastedCloseDate < forecastedDDEndDate) {
            errors.push(
              'Forecasted Close Date must be greater then or equal Forecasted DD End Date'
            );
            return errors;
          }
        } else if (
          isEmpty(forecasted_dd_end_date) &&
          !isEmpty(forecasted_close_date)
        ) {
          let forecastedCloseDate = moment(forecasted_close_date).unix();
          let ddEndDate = moment(
            DateUtility.getDateString(due_diligence_end_c)
          ).unix();

          if (forecastedCloseDate < ddEndDate) {
            errors.push(
              'Forecasted Close Date must be greater then or equal to DD End Date'
            );
          }
        }
      }
    }

    return errors;
  }
};
