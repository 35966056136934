import {
  ActionRecordViewPropTypes,
  EnterSalePendingAmendedEntity
} from 'types/brokerage-action-types';
import {
  prepareRequestBody,
  getValidationFields,
  getInitialState
} from './helper';
import { brokerageActionEvent } from 'event/brokerage-action.event';
import { InputChangeEvent } from 'types/common-types';
import { ErrorValidation } from 'types/error-types';
import { refreshOpportunity } from 'event/opportunity-event';
import { useNavigate } from 'react-router-dom';
import { validateBrokerageAction } from 'helpers/validation/brokerage-action-helper';
import brokerageActionService from 'services/brokerage-action-service';
import emptyFunction from 'helpers/empty-function-helper';
import fieldLabel from 'assets/constants/fieldLabel';
import FormContainer from 'components/form/container';
import PaperBox from 'components/paper-box';
import PaperBoxContent from 'components/paper-box/paper-box-content';
import PaperBoxFooter from 'components/paper-box/paper-box-footer';
import React, { useEffect, useState } from 'react';
import SaveButton from 'components/form/button-save';
import StackRow from 'components/stack/stack-row';
import UnitItem from 'components/form/unit-item';
import UnitText from 'components/form/unit-text';
import { toastError } from 'event/toast-event';
import initialEnterSalePendingAmended from 'state/brokerage-actions/enter-sale-pending-amended';
import { isEmpty } from 'helpers/misc-helper';
import UnitDate from 'components/form/unit-date';
import InfoMessage from 'components/errors/info-component';
import { fieldOptions } from './field-chooser/fields';
import { ObjectType } from 'types';
import FieldChooser from 'features/dashboards/field-chooser/field-chooser';
import reasonDescription from 'assets/constants/reason-description';
import DateUtility from 'helpers/date-helper';

const RecordView = ({
  onClose = emptyFunction,
  opportunity,
  isModal = false,
  loadingOpportunity = false
}: ActionRecordViewPropTypes) => {
  const navigate = useNavigate();

  const [validation, setValidation] = useState<ErrorValidation>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<EnterSalePendingAmendedEntity>(
    initialEnterSalePendingAmended
  );

  const [fields, setFields] = useState<ObjectType>(fieldOptions());

  const handleChange = (e: InputChangeEvent) => {
    setData(Object.assign({}, data, { [e.target.name]: e.target.value }));
  };

  const validateData = () => {
    const fieldToValidate = getValidationFields();
    if (fields.other.checked) {
      fieldToValidate.push('other_transaction_note');
    }

    const { isValid, errors } = validateBrokerageAction(
      data,
      fieldToValidate,
      opportunity
    );

    setValidation(errors);

    return isValid;
  };

  const handleSubmit = async () => {
    const isValid = validateData();

    if (!isValid) return;

    const requestBody = prepareRequestBody(data);

    setIsLoading(true);

    const result =
      await brokerageActionService.update<EnterSalePendingAmendedEntity>(
        opportunity.id,
        requestBody,
        'enter_sale_pending_amended'
      );

    setIsLoading(false);

    if (result.isValidationError) {
      setValidation(result.validationMessage);
      return;
    }

    if (result.isError) {
      toastError(result.errorMessage.message);
      return;
    }

    if (result.isSuccess) {
      if (isModal) {
        onClose();
        brokerageActionEvent.enter_sale_pending_amended();
      } else {
        refreshOpportunity();
        navigate(`/opportunities/${opportunity.id}/view`);
      }
    }
  };

  const handleCheck = (e: InputChangeEvent): void => {
    setFields(() => {
      for (const field of Object.values(fields)) {
        if (field.name.includes(e.target.id)) {
          field.checked = e.target.checked;
          let transactionNotes: any = '';
          let otherTransactionNote = null;
          let reasons: string[] = [];
          let notes: string[] = [];
          if (fields.closing_date_change.checked) {
            if (
              e.target.name == 'closing_date_change' &&
              e.target.value != 'on'
            ) {
              data.forecasted_close_date = e.target.value;
            }
            transactionNotes += `\n Closing Sale Pending Amended - ${
              reasonDescription.closing_date_change
            } - COE Ext to: ${DateUtility.getDateString(
              data.forecasted_close_date
            )}`;
            reasons.push(fields.closing_date_change.name);
            notes.push(`COE Ext to: ${data.forecasted_close_date}`);
          }
          if (fields.preliminary_docs.checked) {
            transactionNotes += `\n Closing Sale Pending Amended - ${reasonDescription.preliminary_docs} - Correction Needed`;
            reasons.push(fields.preliminary_docs.name);
            notes.push('Correction Needed');
          }
          if (fields.entity_assignment.checked) {
            transactionNotes += `\n Closing Sale Pending Amended - ${reasonDescription.entity_assignment} - Buyer Name Change`;
            reasons.push(fields.entity_assignment.name);
            notes.push('Buyer Name Change');
          }
          if (fields.other.checked) {
            if (e.target.name == 'other' && e.target.value != 'on') {
              otherTransactionNote = e.target.value;
            } else {
              otherTransactionNote = data.other_transaction_note;
            }

            reasons.push(fields.other.name);
            notes.push(otherTransactionNote);
          }

          setData((prevData) => ({
            ...prevData,
            transaction_notes: transactionNotes,
            reasons,
            notes
          }));
        }
      }
      return { ...fields };
    });
  };

  useEffect(() => {
    setData((prevData) => {
      return {
        ...prevData,
        ...getInitialState(opportunity),
        transaction_notes: null
      };
    });
  }, [loadingOpportunity]);

  return (
    <>
      <PaperBox variantValue="elevation" sx={{ p: 0 }}>
        <PaperBoxContent
          sx={{
            height: 'calc(100vh - 48vh)',
            overflowY: 'auto',
            p: 2
          }}
        >
          <FormContainer>
            <UnitItem grid={{ xs: 12, sm: 12 }} p={3}>
              <FieldChooser
                label={'Type (Select to continue)'}
                data={fields}
                setFields={handleCheck}
              />
            </UnitItem>
          </FormContainer>
          <FormContainer>
            <UnitDate
              label={fieldLabel.forecastedCloseDate}
              name="forecasted_close_date"
              value={data.forecasted_close_date ?? ''}
              error={validation['forecasted_close_date'] ?? ''}
              required
              onChange={(value: any) => {
                handleChange({
                  target: {
                    name: 'forecasted_close_date',
                    value: value
                  }
                });
                handleCheck({
                  target: {
                    name: 'closing_date_change',
                    value: value,
                    checked: true,
                    id: 'closing_date_change'
                  }
                });
              }}
              grid={{ xs: 12, sm: 6 }}
            />
            <UnitDate
              label={fieldLabel.closeDate}
              name="close_date_c"
              value={data.close_date_c ?? ''}
              onChange={(e: string) =>
                handleChange({
                  target: { name: 'close_date_c', value: e }
                })
              }
              error={validation['close_date_c'] ?? ''}
              required
              grid={{ xs: 12, sm: 6 }}
            />
            {!isEmpty(
              validation?.close_date_c_federal_holiday_validation_message
            ) && (
              <InfoMessage
                message={
                  validation?.close_date_c_federal_holiday_validation_message ??
                  ''
                }
              />
            )}
            <UnitDate
              name="contract_amendment_date_c"
              label={fieldLabel.contractAmendmentDate}
              value={data?.contract_amendment_date_c ?? ''}
              onChange={(e) =>
                handleChange({
                  target: { name: 'contract_amendment_date_c', value: e }
                })
              }
              grid={{ xs: 12, sm: 6 }}
            />
            <UnitText
              label={fieldLabel.addendumNo}
              name="addendum_no"
              value={data.addendum_no ?? ''}
              onChange={handleChange}
              grid={{ xs: 12, sm: 6 }}
            />
            <UnitText
              label={fieldLabel.transactionNotes}
              name="transaction_notes"
              value={data?.transaction_notes ?? ''}
              onChange={handleChange}
              disabled
              multiline
              rows={4}
              error={validation['transaction_notes'] ?? ''}
              grid={{ xs: 12, sm: 6 }}
            />
            <UnitText
              label={fieldLabel.addendumNotes}
              name="addendum_notes"
              value={data.addendum_notes ?? ''}
              onChange={handleChange}
              multiline
              rows={4}
              grid={{ xs: 12, sm: 6 }}
            />
            <UnitText
              label={fieldLabel.otherTransactionNote}
              name="other_transaction_note"
              value={data.other_transaction_note ?? ''}
              onChange={(e: any) => {
                handleChange(e);
                handleCheck({
                  target: {
                    name: 'other',
                    value: e.target.value,
                    checked: true,
                    id: 'other'
                  }
                });
              }}
              required={fields.other.checked}
              disabled={!fields.other.checked}
              multiline
              rows={4}
              error={validation['other_transaction_note'] ?? ''}
              grid={{ xs: 12, sm: 6 }}
            />
          </FormContainer>
        </PaperBoxContent>
        <PaperBoxFooter>
          <StackRow sx={{ pt: 0, pr: 0, pb: 0, pl: 0 }}>
            <UnitItem grid={{ xs: 12, sm: 2 }}>
              <SaveButton onClick={handleSubmit} disabled={isLoading} />
            </UnitItem>
          </StackRow>
        </PaperBoxFooter>
      </PaperBox>
    </>
  );
};

export default RecordView;
